@import url(https://fonts.googleapis.com/css?family=PT+Sans+Narrow);
@import url(https://fonts.googleapis.com/css2?family=Abhaya+Libre&family=Copse&family=Poppins&family=Work+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dashboard {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(96, 107, 114);
  min-height: 80vh;
  font-size: 40px;
}

.headerMain {
  background: #011633;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
  position: fixed;
  min-width: 100vw;
  z-index: 2;
}
.headerMainRight {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20.7499px;
  /* identical to box height */
  color: #e7f1ff;
}
.headerMainRight > p {
  cursor: pointer;
}
.headerMainLeft {
  margin-left: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 50px;
  gap: 50px;
}

.headerMain svg {
  height: 30px;
}

.headerMainLeft {
  font-family: Copse;
  font-style: normal;
  font-weight: normal;
  font-size: 42.633px;
  color: #e7f1ff;
}

.headerMain .css-1s2u09g-control {
  background-color: transparent;
  border: none;
}

.headerMain .css-1okebmr-indicatorSeparator {
  display: none;
}

.headerMain .css-6j8wv5-Input {
  color: #e7f1ff;
}
.headerMain .css-14el2xx-placeholder {
  color: #e7f1ff;
}

.headerMain .css-qc6sy-singleValue {
  color: #e7f1ff;
}
.headerMain .css-1pahdxg-control {
  background-color: transparent;
  color: #e7f1ff;
}

.headerMain .css-2613-qy-menu {
  color: #011633;
  border-bottom: 1px solid grey;
  background-color: blue;
}

.headerMain .css-1jwi8f4-singleValue {
  color: #e7f1ff;
}
@media screen and (min-width: 250px) and (max-width: 600px) {
  .headerMainLeft {
    margin-left: 5px;
    font-size: 20px;
    grid-gap: 0;
    gap: 0;
  }
  .headerMain .Junk {
    font-size: 10px;
  }
  .headerMainRight {
    grid-gap: 2px;
    gap: 2px;
    font-size: 15px;
  }
}

.sidebarmain {
  margin: 0;
  padding: 0;
  width: 100px;
  background-color: #011633;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  position: fixed;
  align-items: center;
  justify-content: center;
}

.sidebarmain_bg {
  margin-top: 30%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fb5517;
  cursor: pointer;
}

.sidebarmain div svg {
  height: 30px;
}

.sidebar_active {
  background-color: #ffffff;
}

.download {
  display: grid;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
  grid-template-columns: repeat(3, 1fr);
  color: black;
  padding-top: 2%;
  padding-left: 2%;
  grid-gap: 20px;
}
.downloadgrid {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid #fb5517;
  height: 150px;
  grid-gap: 10px;
  gap: 10px;
  width: 400px;
}
.downloadgrid1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid #fb5517;
  height: 150px;
  width: 400px;
  grid-gap: 5px;
  gap: 5px;
}
.downloadgridlatest {
  color: #222324;
  font-size: 20px;
  margin: 0;
}
.downloadgridversion {
  color: #011633;
  font-size: 25px;
  margin: 0;
}
.download button {
  width: 150px;
  height: 40px;
  font-size: 20px;
  border-radius: 67px;
  background-color: #fb5517;
  border-style: none;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  color: #e7f1ff;
  cursor: pointer;
}

.download button:hover {
  background-color: #ffff;
  border: 2px solid #fb5517;
  color: #fb5517;
}

.downloadgridbuttonbox {
  display: flex;
  grid-gap: 2%;
  gap: 2%;
}

@media screen and (min-width: 250px) and (max-width: 600px) {
  .download {
    display: flex;
    flex-direction: column;
    padding-top: 20%;
  }
  .downloadgrid,
  .downloadgrid1 {
    margin-top: 10%;
    width: 200px;
    height: 150px;
  }
  .downloadgridversion {
    font-size: 20px;
  }
  .download button {
    width: 100px;
    font-size: 15px;
  }
}

.github {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
}
.github svg {
  cursor: pointer;
}
.github_heading {
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 30.89px;
  text-align: center;
  color: #fb5517;
  margin: 0;
}
.github_subheading1,
.github_subheading2 {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  text-align: center;
  color: #797979;
  margin: 0;
}
.github_subheading3 {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  text-align: center;
  color: #fb5517;
  margin: 0;
}
.github span {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 5px;
  gap: 5px;
}

.project_creation {
  /* min-height: 80vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.project_creationbox {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80vw;
  grid-gap: 30px;
  gap: 30px;
}
.project_creationbox > form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  grid-gap: 30px;
  gap: 30px;
}
.createproject_formbox {
  width: 100%;
  background: #f3f5f9;
  box-shadow: 0px 0px 31px 1px rgba(0, 0, 0, 0.1);
  border-radius: 1px;
  padding: 3%;
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}

.create_project_name {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 40px;
  gap: 40px;
}
.create_project_name_input1 {
  width: 70%;
  height: 40px;
  background: #ffffff;
  border: 1px solid #fb5517;
  box-sizing: border-box;
  border-radius: 56px;
  padding-left: 10px;
}
.create_project_name_input2 {
  height: 40px;
  width: 20%;
  background: #ffffff;
  border: 1px solid #fb5517;
  box-sizing: border-box;
  padding-left: 10px;
  border-radius: 56px;
}
.create_project_sec2 {
  display: flex;
  flex-direction: column;
  grid-gap: 30px;
  gap: 30px;
}
.create_project_sec2 input[type="text"] {
  width: 100%;
  background: #ffffff;
  border: 1px solid #fb5517;
  box-sizing: border-box;
  border-radius: 56px;
  height: 40px;
  color: #365175 !important;
  padding-left: 20px;
}

.create_project_sec2 .react-datepicker-wrapper {
  width: 200px;
}
.create_project_sec2 .css-b62m3t-container {
  width: 400px;
}

.create_project_sec2 .css-1s2u09g-control {
  background: #ffffff;
  border: 1px solid #fb5517;
  box-sizing: border-box;
  border-radius: 56px;
}

.create_project_sec2 > div {
  display: flex;
  grid-gap: 50px;
  gap: 50px;
  align-items: center;
}

.create_project_sec3 .iciELI {
  max-width: 100% !important;
  height: 100px;
  background: #ededed;
  border: 1px dashed #000000;
  box-sizing: border-box;
  padding-left: 100px;
  padding-right: 50px;
}

.create_project_sec3 span {
  font-size: 15px !important;
  font-weight: 500;
}

.create_project_sec3 .kCudqJ span {
  font-size: 15px !important;
  text-decoration: none;
}
.create_project_sec3 .iciELI svg {
  display: none;
}
.create_project_sec3 .ebdQkY .file-types {
  max-width: none;
}

.createProject_submit {
  background: #fb5517;
  border-radius: 67px;
  width: 300px;
  height: 50px;
  border-style: none;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  /* line-height: 42px; */
  text-align: center;
  color: #e7f1ff;
}
.createProject_submit:hover {
  background-color: #ffffff;
  cursor: pointer;
  color: #fb5517;
  border: 1px solid #fb5517;
}
.createProject_Cancel {
  border: 3px solid #fb5517;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 67px;
  width: 300px;
  height: 50px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  text-align: center;
  color: #fb5517;
}
.createProject_Cancel:hover {
  background-color: #fb5517;
  color: #ffffff;
}

.MainPage {
  padding-top: 90px;
  padding-left: 100px;
}

.SubTaskTopButtons {
  display: flex;
  grid-gap: 40px;
  gap: 40px;
  padding: 10px;
}

.SubTaskTopButtons .active {
  color: #fb5517;
}
.Subtask_button1 {
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 25.89px;
  text-align: center;
  cursor: pointer;
  color: #c4c4c4;
}

.barCompleted {
  background-color: #fb5517;
}
.container {
  border-radius: 10px;
  background-color: #e4e4e4;
}
.wrapper {
  border-radius: 10px;
}
.subprojects_sec2 {
  display: flex;
  flex-direction: column;
}
.subprojects_sec2_left {
  width: 10%;
}

.subtask_projects {
  background: #f3f5f9;
  box-shadow: 0px 0px 31px 1px rgba(0, 0, 0, 0.1);
  border-radius: 1px;
  margin: 10px 30px;
}
.subprojects_sec1 {
  display: flex;
  justify-content: space-between;
}
.subprojects_sec1 svg {
  width: 50%;
}
.subprojects_sec1left {
  width: 80%;
  padding: 15px;
}
.subprojects_sec1left_heading {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  color: #011633;
  margin: 0;
}
.subprojects_sec1left_subheading {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: #011633;
  margin: 0;
}
.subprojects_sec1left_subheading2 {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #797979;
}

.signup {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: 85vh;
}

.signuptop > form {
  /* width: 25vw; */
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
}

.signuptopitem1,
.signuptopitem2,
.signuptopitem3,
.signuptopitem4 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
}

.signuptopitem1 label {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16.1844px;
  line-height: 19px;
  text-align: right;
  color: #797979;
  margin-bottom: 10px;
}

.signuptop input[type="text"],
.signuptop input[type="email"],
.signuptop input[type="password"] {
  border: 1px solid #797979;
  box-sizing: border-box;
  border-radius: 35.5px;
  width: 100%;
  height: 50px;
  padding-left: 5%;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20.89px;
  line-height: 32px;
  /* identical to box height */

  color: #bfbfbf;
}
.signuptop input::placeholder {
  padding-left: 5%;
}

.signupformfooter {
  display: flex;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14.1844px;
  align-items: center;
  line-height: 19px;
}
.signupformtextbox {
  display: flex;
  align-items: center;
  /* width: 140%; */
  justify-content: center;
  grid-gap: 5px;
  gap: 5px;
}

.signupformtextbox > p {
  /* width: 140%; */
}

.signupformerrors {
  color: red;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14.1844px;
  line-height: 19px;
  text-align: left;
}

.signup button[type="submit"] {
  width: 100%;
  background: #fb5517;
  border-radius: 35.5px;
  height: 50px;
  border-style: none;
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 25.89px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}
.signupformcheckboxitem2,
.signupformcheckboxitem4 {
  color: #1074ff;
}

.signupbottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin-top: 10px; */
}

.signuplines {
  width: 150px;
  background-color: #797979;
  height: 2px;
}

.signupbottom_item1 {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #797979;
}

.signupbottom_button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 2px solid #fb5517;
  box-sizing: border-box;
  border-radius: 35.5px;
  width: 250px;
  height: 50px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 25.89px;
  line-height: 36px;
  text-align: center;
  color: #fb5517;
}
.signup_social_provider {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
  margin-top: 10px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 23px;
  color: #797979;
}

.signup_social_provider_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 250px) and (max-width: 600px) {
  .signuplines {
    width: 50%;
  }
  .signuptopitem1,
  .signuptopitem2,
  .signuptopitem3,
  .signuptopitem4 {
    width: 80%;
  }
  .signup button[type="submit"] {
    width: 80%;
  }
  .signupformtextbox > p {
    margin: 0;
    text-align: center;
  }
  .signupformfooter {
    width: 80%;
  }
  .signupbottom {
    margin-bottom: 20px;
  }
  .signupbottom_item1 {
    grid-gap: 0px;
    gap: 0px;
  }
}

.signin {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content:center;
  margin-top: -20px; 
  min-height:90vh;
}
.signin > form {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
  min-width: 25vw;
}
.signintopitem1,
.signintopitem2,
.signintopitem3 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
}
.signintopitem3 {
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;

  color: #797979;
}
.signin input[type="text"],
.signin input[type="email"],
.signin input[type="password"] {
  border: 1px solid #797979;
  box-sizing: border-box;
  border-radius: 35.5px;
  width: 100%;
  height: 50px;
  padding-left: 5%;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20.89px;
  line-height: 32px;
  /* identical to box height */

  color: #bfbfbf;
}
.signin input::placeholder {
  padding-left: 5%;
}
.signinformerrors {
  color: red;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14.1844px;
  line-height: 19px;
  text-align: left;
}
.signin button[type="submit"] {
  width: 100%;
  background: #fb5517;
  border-radius: 35.5px;
  height: 50px;
  border-style: none;
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 25.89px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}
.signinlines {
  width: 150px;
  background-color: #797979;
  height: 2px;
}
.signinbottom_item1 {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #797979;
}
.signin_social_provider {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
  margin-top: 10px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 23px;
  color: #797979;
}
.signin_social_provider_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.signin_bottom_footer {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 5px;
  gap: 5px;
}
.signinlinesfooter {
  width: 200px;
  background-color: #797979;
  height: 2px;
}
.signin_bottom_footer_text {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #797979;
}
.signin_footer_button > button {
  cursor: pointer;
  background: #ffffff;
  border: 2px solid #fb5517;
  box-sizing: border-box;
  border-radius: 35.5px;
  width: 250px;
  height: 50px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 25.89px;
  line-height: 36px;
  text-align: center;
  color: #fb5517;
}

.signup_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 2%;
  padding-right: 2%;
  background: #011633;
  height: 90px;
}
.signup_header_left {
  font-family: Copse;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  /* identical to box height */
  color: #f6faff;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
  margin: 0;
}
.signup_header_right {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20.7499px;
  line-height: 36px;
  color: #f3f3f3;
}
.signup_header .Dropdown-control {
  position: relative;
  overflow: hidden;
  border-radius: 2px;
  box-sizing: border-box;
  background-color: transparent;
  border-style: none;
  color: #ffffff;
  cursor: default;
  outline: none;
  padding: 8px 25px 8px 10px;
  transition: all 200ms ease;
}
.signup_header .css-1s2u09g-control {
  background-color: transparent;
  border: none;
}

.signup_header .css-1okebmr-indicatorSeparator {
  display: none;
}

.signup_header .css-6j8wv5-Input {
  color: #e7f1ff;
}
.signup_header .css-14el2xx-placeholder {
  color: #e7f1ff;
}

.signup_header .css-qc6sy-singleValue {
  color: #e7f1ff;
}
.signup_header .css-1pahdxg-control {
  background-color: transparent;
  color: #e7f1ff;
}

.signup_header .css-2613-qy-menu {
  color: #011633;
  border-bottom: 1px solid grey;
  background-color: blue;
}

.signup_header .css-1jwi8f4-singleValue {
  color: #e7f1ff;
}
@media screen and (min-width: 250px) and (max-width: 600px) {
  .headerlogotext {
    font-size: 20px !important;
  }
  .signup_header .Junk {
    font-size: 12px;
  }
  .signup_header .css-14el2xx-placeholder {
    font-size: 15px;
  }
}

.welcome {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
}
.welcome p {
  font-family: Copse;
  font-style: normal;
  font-weight: normal;
  font-size: 54.4757px;
  line-height: 40px;
  text-align: center;
  color: #fb5517;
}
.welcome button {
  background: #fb5517;
  border-radius: 35.5px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 25.89px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
  width: 300px;
  height: 50px;
  border-style: none;
}

.goals {
  padding: 5%;
  margin-top: -100px;
}
.goals_title {
  font-family: Copse;
  font-style: normal;
  font-weight: normal;
  font-size: 54.4757px;
  /* line-height: 80px; */
  color: #fb5517;
  text-align: center;
}
.goals_grid {
  display: grid;
  grid-gap: 1px;
  grid-template-columns: repeat(3, 1fr);
}
.goal_grid_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.goal_grid_box > img {
  width: 100px;
  height: 100px;
}
.goal_grid_box > p {
  padding: 20px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 20.8064px;
  line-height: 132%;
  text-align: center;
  letter-spacing: 0.015em;
  color: #011632;
}
@media screen and (min-width: 250px) and (max-width: 600px) {
  .goals_title {
    font-size: 44px;
  }
  .goals {
    /* margin-top: -20px; */
    text-align: center;
  }
  .goals_grid {
    grid-template-columns: repeat(2, 1fr);
    display: block;
  }
  .goal_grid_box > p {
    line-height: normal;
  }
}

.Footer {
  background-color: #011633;
  /* position: fixed; */
  width: auto;
  /* bottom: 0; */
  padding: 1% 1% 0% 2%;
  font-size: 25px;
  flex-direction: column;
}
.Footer svg {
  cursor: pointer;
}

.Footer a {
  color: #ffffff;
  text-decoration: none;
}

.footertop {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 0.991933px solid #ffffff;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.footertopleft {
  display: flex;
}

.footertopleftsection1 {
  cursor: pointer;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 15.8548px;
  line-height: 27px;
  /* identical to box height */
  color: #ffffff;
  /* height: 400px; */
}
.footertopleftsection2 {
  cursor: pointer;
}
.footertopleftsection2heading {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 15.8548px;
  line-height: 27px;
  /* identical to box height */
  color: #ffffff;
}

.footertopleftsection1bottom {
  margin-top: 40px;
  font-size: 22.7675px;
}
.footertopleftseaction1bottom1 {
  font-family: Copse;
  font-style: normal;
  font-weight: normal;
  font-size: 19.7675px;
  line-height: 24px;
  /* identical to box height */

  color: #f6faff;
}
.footertopleftseaction1bottom2 {
  font-family: Copse;
  font-style: normal;
  font-weight: normal;
  font-size: 18.7675px;
  /* line-height: 24px; */
  /* identical to box height */
  margin-top: -1%;
  color: #f6faff;
}

.footertopright {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 17.8548px;
  line-height: 27px;
  /* identical to box height */
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  /* height: 380px; */
}
.footertoprightbox1item {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.footertoprightbox2 {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
}
.footertoprightbox2specialcolor {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 17.8548px;
  line-height: 21px;
  color: #fb5517;
}

.footerbottom {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 15.8387px;
  line-height: 30px;
  /* identical to box height */
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  /* gap: 30%; */
}
@media screen and (min-width: 250px) and (max-width: 600px) {
  .Footer {
    display: flex;
    padding: 5% 2% 0% 2%;
    flex-direction: column;
    background-color: #011633;
  }
  .footertopleft {
    font-size: 10px !important;
  }
  .footertopleftsection1 {
    font-size: 10px;
    width: 100px;
    line-height: normal;
    height: auto;
  }
  .footertopright {
    font-size: 10px;
    /* height: 200px; */
  }
  .footertopright svg {
    width: 30px;
    height: 20px;
  }
  .footertopleftsection1bottom {
    margin-top: 20px;
    font-size:14px;
  }
  .footertopleftseaction1bottom2 {
    font-size: 8px;
  }
  .footertopleftsection2 {
    font-size: 10px !important;
    width: 50px;
  }
  .footertopleftsection2heading {
    font-size: 10px;
    line-height: normal;
  }
  .footertoprightbox2 {
    font-size: 10px;
    align-items: flex-start;
    /* text-align: justify; */
  }
  .footertoprightbox2specialcolor {
    font-size: 10px;
    width: 200px;
  }
  .footertoprightbox2 {
    flex-direction: column;
    line-height: normal;
    grid-gap: 0px;
    gap: 0px;
  }
  .footerbottom {
    font-size: 10px;
  }
}

.Events {
  padding: 2%;
  background: #011633;
  padding-bottom: 2%;
}

.Events > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  margin-bottom: 5%;
  padding-bottom: 5%;
}

.event_title {
  font-family: Copse;
  font-style: normal;
  font-weight: normal;
  font-size: 50px;
  line-height: 55px;
  color: #fb5517;
}

.events_project_title {
  font-family: Work Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 30.7617px;
  /* line-height: 27px; */
  color: #fb5517;
}
.events_project_description {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 23.7499px;
  line-height: 36px;
  text-align: justify;
  color: #000000;
  padding-right: 15px;
}

.events_slider {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: 5%;
  margin-right: 5%;
}

.events_left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 60%;
}

.Events .swiper-button-prev,
.Events .swiper-button-next {
  color: rgb(178, 22, 22);
}
.swiper-button-prev:after, .swiper-button-next:after{
  font-size: 0px !important;
}
.events_right {
  height: clamp(60vh, 50%, 70vh);
  /* display: grid; */
  align-items: center;
  width: clamp(60vw, 10%, 25vw);
}

@media screen and (min-width: 250px) and (max-width: 600px) {
  .events_right {
    height: clamp(60vh, 50%, 70vh);
    /* display: grid; */
    align-items: center;
    width: clamp(80vw, 10%, 25vw);
  }
  .events_slider {
    flex-direction: column-reverse;
    width: 100vw;
    align-items: center;
    margin-left: 0;
    margin-right: 0;
  }
  .Events {
    padding: 0%;
  }
  .events_left {
    align-items: center;
    width: 80%;
  }
  .events_project_title {
    font-size: 20px;
    color: #fb5517;

  }
  .event_title{
    font-size: 30px;
  }

  .events_project_description {
    font-size: 15px;
    text-align: center;
    line-height: normal;
  }

  .Events {
    margin-bottom: 10%;
  }
  .Events .swiper-container {
    width: 100vw;
  }
}

.video {
  display: flex;
  justify-content: center;
  /* background-color: rebeccapurple; */
  min-height: 80vh;
  position: relative;
}

.videocontainer {
  margin-top: 8vh;
  padding-bottom: 8vh;
  width: 90vw;
  padding-top: 10vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: #ffffff;
  grid-gap: 5vw;
  gap: 5vw;
  box-shadow: 9.91933px 12.8951px 247.983px rgba(0, 0, 0, 0.1);
}

.videobox {
  margin-left: 5vw;
  /* height: clamp(60vh, 50%, 70vh); */
  display: grid;
  align-items: center;
  width: clamp(30vw, 50%, 35vw);
}

.videocontent {
  height: 50%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.video_heading {
  font-family: Copse;
  font-style: normal;
  font-weight: normal;
  font-size: 44.637px;
  line-height: 55px;
  color: #fb5517;
  text-align: justify;
}

.video_subheading {
  margin-top: -5vh;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 31.7617px;
  line-height: 37px;
  text-align: justify;
  color: #011633;
  padding-right: 10px;
}

.video_description {
  margin-top: -2vh;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 26.0694px;
  padding-right: 10px;
  text-align: justify;
  line-height: 36px;
  color: #000000;
}

.video_buttons {
  /* margin-top: 10vh; */
  display: flex;
  grid-gap: 3vw;
  gap: 3vw;
}

.video_button1 {
  width: clamp(25%, 30%, 45%);
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 25.8851px;
  line-height: 37px;
  color: #e7f1ff;
  background: #fb5517;
  border-style: none;
  border-radius: 26.0395px;
}

.video_button2 {
  width: clamp(15%, 20%, 35%);
  border: 2.02905px solid #fb5517;
  box-sizing: border-box;
  border-radius: 26.0395px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 25.8851px;
  line-height: 37px;
  color: #fb5517;
  background-color: transparent;
}

.video button:hover {
  background: linear-gradient(94.6deg, #fb5517 36.28%, #ed1e1e 113.49%);
  border-radius: 32.5494px;
  color: #ffffff;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

@media screen and (min-width: 250px) and (max-width: 600px) {
  .videocontainer {
    flex-direction: column;
    margin-top: 5vh;
    padding-top: 0;
    min-height: 90vh;
  }
  .video_button2 {
    width: clamp(8%, 15%, 35%);
  }
  .video_buttons{
    display: flow-root;
    text-align: center;
  }
  .videobox {
    width: 100%;
    margin-left: 0;
    max-height: 50vh;
  }

  .video {
    min-height: 20vh;
    position: relative;
  }

  .videocontent {
    width: 100%;
    /* min-height: 25vh; */
    height: 60%;
    grid-gap: 10px;
    gap: 10px;
  }

  .video_heading {
    font-size: 20px;
    line-height: normal;
  }

  .video_subheading {
    font-size: 15px;
    line-height: normal;
  }

  .video_description {
    font-size: 14px;
    line-height: normal;
  }

  .video_button1,
  .video_button2 {
    width: 150px;
    font-size: 16px;
    margin-bottom: 3vh;
    margin-left:1vh;
  }
  
}

@media screen and (min-width: 1600px) and (max-width: 2600px) {
  .video {
    min-height: 60vh;
  }

  .videobox {
    height: 50vh;
  }

  .videocontent {
    height: 60%;
  }
  
}

button {
  cursor: pointer;
}

.LandingHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #011633;
  height: 90px;
  position: fixed;
  width: 100%;
  /* margin-top: 20px; */
  /* padding: 0.5rem 1rem 0.5rem 1rem; */
  z-index: 2;
}

.headerlogo {
  width: 80px;
  height: 80px;
  cursor: pointer;
}

.Junk2 {
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.Junk {
  font-size: 15px;
  font-style: italic;
  color: #abb0b8;
  margin: 0;
}

.LandingHeaderLeft {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-family: Copse;
  grid-gap: 2.5rem;
  gap: 2.5rem;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  color: #f6faff;
  padding-left: 2rem;
}

.headerlogotext {
  margin: 0;
  font-family: Copse;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  color: #f6faff;
}

.LandingHeaderLeft > ul {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  list-style: none;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  /* line-height: 36px; */
  color: #f3f3f3;
}
.LandingHeaderLeft > ul > li:hover {
  color: #fb5517;
}
.LandingHeaderRight {
  padding-right: 2rem;
  display: flex;
  align-items: center;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.LandingHeaderRight .Dropdown-control {
  position: relative;
  overflow: hidden;
  border-radius: 2px;
  box-sizing: border-box;
  background-color: transparent;
  border-style: none;
  color: #ffffff;
  cursor: default;
  outline: none;
  padding: 8px 25px 8px 10px;
  transition: all 200ms ease;
}

.LandingHeaderRightLeft {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  color: #f3f3f3;
}
.LandingHeaderRightLeft > span {
  font-size: 2.5rem;
  font-weight: 400;
}

.LandingHeaderRightLeft > p:hover {
  cursor: pointer;
  color: #fb5517;
}

.LandingHeaderRight button {
  cursor: pointer;
  background: #fb5517;
  border-radius: 24.3024px;
  border-style: none;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  min-width: 100px;
  line-height: 35px;
  color: #e7f1ff;
}

.LandingHeaderRight > button:hover {
  background: linear-gradient(94.6deg, #fb5517 36.28%, #ed1e1e 113.49%);
  border-radius: 32.5494px;
}

.LandingHeader .css-1s2u09g-control {
  background-color: transparent;
  border: none;
}

.LandingHeader .css-1okebmr-indicatorSeparator {
  display: none;
}

.LandingHeader .css-6j8wv5-Input {
  color: #e7f1ff;
}
.LandingHeader .css-14el2xx-placeholder {
  color: #e7f1ff;
}

.LandingHeader .css-qc6sy-singleValue {
  color: #e7f1ff;
}
.LandingHeader .css-1pahdxg-control {
  background-color: transparent;
  color: #e7f1ff;
}

.LandingHeader .css-2613-qy-menu {
  color: #011633;
  border-bottom: 1px solid grey;
  background-color: blue;
}

.LandingHeader .css-1jwi8f4-singleValue {
  color: #e7f1ff;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 12px;
  z-index: 1;
  border-radius: 2%;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content > p {
  color: black;
}

.dropdown-content > p:hover {
  color: blue;
}

#mobile-menu-btn {
  display: none;
}

.contactus {
  padding: 3%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.contactusleft {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.contactus_heading {
  font-family: Copse;
  font-style: normal;
  font-weight: normal;
  font-size: 54.4757px;
  line-height: 80px;
  color: #fb5517;
}

.contactusform {
  margin-top: -5%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  background: #fefefe;
  /* border: 2.9758px solid #fb5517; */
  padding: 2%;
  box-sizing: border-box;
  box-shadow: 9.91933px 19.8387px 49.5967px rgba(0, 0, 0, 0.1);
}
.contactusformheading {
  font-family: Copse;
  font-style: normal;
  font-weight: 500;
  font-size: 40.947px;
  line-height: 51px;
  /* identical to box height */
  color: #6a6a6a;
  margin-top: -2%;
  opacity: 0.8;
  text-align: justify;
}

.contactusform > form {
  display: flex;
  flex-direction: column;
}

.contactusform label {
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 22.298px;
  line-height: 32px;

  color: #000000;

  opacity: 0.5;
}

.contactusform input[type="text"],
.contactusform input[type="email"],
.contactusform select {
  border: 1.13742px solid #000000;
  box-sizing: border-box;
  border-radius: 5.68708px;
  opacity: 0.5;
  margin-top: 1%;
  height: 30px;
  width: 30vw;
  font-size: 20px;
}

.contactusform input[type="submit"] {
  margin-top: 30px;
  background: #fb5517;
  box-shadow: 2.27483px 4.54967px 0px rgba(86, 109, 73, 0.73);
  border-radius: 44.3593px;
  height: 50px;
  width: 20vw;
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 30.947px;
  line-height: 48px;
  /* identical to box height */

  text-transform: uppercase;

  color: #ffffff;

  border-style: none;
}

.contactusform textarea {
  border: 1.13742px solid #000000;
  box-sizing: border-box;
  border-radius: 5.68708px;
  height: 150px;
  font-size: 25px;
  opacity: 0.5;
}

.contactusright {
  background: #011633;
  box-shadow: 9.91933px 9.91933px 29.758px rgba(0, 0, 0, 0.1);
  height: 400px;
  /* margin-top: 150px; */
  padding: 2%;
  width: 40%;
}

.contact_right_title {
  font-family: Copse;
  font-style: normal;
  font-weight: normal;
  font-size: 32.0381px;
  line-height: 52px;
  /* identical to box height */
  color: #fb5517;
}

.contact_right_description {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 25.7499px;
  text-align: justify;
  line-height: 36px;

  color: #ffffff;
}
.contact_right_items {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  grid-gap: 30px;
  gap: 30px;
}
.contact_right_content {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 25.7499px;
  text-align: justify;
  line-height: 36px;

  color: #ffffff;
}
@media screen and (min-width: 250px) and (max-width: 600px) {
  .contactus {
    flex-direction: column;
  }
  .contactusleft {
    width: 100%;
  }
  .contactusform input[type="submit"] {
    width: 300px;
  }
  .contactusform input[type="text"],
  .contactusform input[type="email"],
  .contactusform select {
    width: 80%;
  }
  .contactusform > form {
    width: 100%;
  }
  .contactusright {
    width: 90%;
    margin-top: 30px;
    height: 100%;
  }
  .contact_right_title {
    font-size: 25px;
    line-height: normal;
  }
  .contact_right_description {
    font-size: 18px;
    line-height: normal;
  }
  .contact_right_content {
    font-size: 18px;
    line-height: normal;
  }
}

.mobile_header {
  width: 100%;
  z-index: 10;
  height: 4.4em;
  display: flex;
  position: fixed;
  align-items: center;
  background: #011633;
  background-image: initial;
  justify-content: space-between;
  background-color: rgb(1, 18, 41);
}

.headerlogomobiletext {
  font-family: Copse;
  font-style: normal;
  font-weight: normal;
  font-size: 20.0192px;
  line-height: 25px;
  /* identical to box height */
  color: #f6faff;
  margin: 0;
}

.mobile_header .headerlogo {
  width: 50px;
  height: 50px;
  margin-left: 10px;
}

.mobile_header .Junk {
  font-size: 8px;
}

.mobile_header_box {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.mobile_header_box>span {
  color: #f3f3f3;
}

.mobile_header_box>p {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12.0134px;
  line-height: 16px;

  color: #f3f3f3;
}

.mobile_header .css-1s2u09g-control {
  background-color: transparent;
  border: none;
}

.mobile_header .css-1okebmr-indicatorSeparator {
  display: none;
}

.mobile_header .css-6j8wv5-Input {
  color: #e7f1ff;
}

.mobile_header .css-14el2xx-placeholder {
  color: #e7f1ff;
}

.mobile_header .css-qc6sy-singleValue {
  color: #e7f1ff;
}

.mobile_header .css-1pahdxg-control {
  background-color: transparent;
  color: #e7f1ff;
}

.mobile_header .css-2613-qy-menu {
  color: #011633;
  border-bottom: 1px solid grey;
  background-color: blue;
}

.mobile_header .css-1jwi8f4-singleValue {
  color: #e7f1ff;
}

#mobile-menu-btn {
  color: white;
  display: block;
  font-size: 1.5em;
}

.mobile-menu-btn-div {
  margin-right: 5%;
}

.ul_div {
  top: 7.4%;
  width: 100%;
  z-index: 10;
  position: fixed;
  text-align: center;
  background: black;
}

.ul_div > ul {
  list-style: none;
  padding: 0%;
  color: white;
}

.ul_div > ul > li {
  padding: 2% 5%;
  font-weight: 600;
}

.ul_div > ul > li p {
  margin: 2%;
}

@media (max-width: 600px) {
  .dropdown-content {
    right: 0%;
  }

  .dropdown-content p {
    text-align: left;
    padding-bottom: 10px;
    color:#011633;
  }

  .LandingHeaderRight {
    display: block;
    padding: 0%;
  }
  
  /* .banner_heading {
    margin-top: 15%;
  } */
}

.newsletter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 3%; */
  background: #011633;
  text-align: center;
  min-height: 30vh;
}
.newsletter_title {
  font-family: Copse;
  font-style: normal;
  font-weight: normal;
  font-size: 60.4757px;
  line-height: 80px;
  color: #fb5517;
}
.newsletter_subheading {
  margin-top: -4%;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 35.72px;
  /* line-height: 57px; */
  text-align: center;
  color: #ffffff;
}
.newsletter_description {
  margin-top: -2%;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 35.72px;
  text-align: center;
  color: #ffffff;
}

.newsletter form {
  display: flex;
  grid-gap: 2%;
  gap: 2%;
  align-items: center;
  justify-content: center;
}

.newsletter input[type="email"] {
  background: #ffffff;
  border: 2px solid #fb5517;
  box-sizing: border-box;
  border-radius: 14px;
  width: 55vw;
  height: 70px;
  font-size: 30px;
}

.NewsletterButton {
  cursor: pointer;
  background: #fb5517;
  border-radius: 43.5px;
  border-style: none;
  width: 300px;
  font-family: Copse;
  font-style: normal;
  font-weight: normal;
  font-size: 33.7297px;
  line-height: 66px;
  color: #ffffff;
}

.NewsletterButton:hover {
  background: linear-gradient(97.02deg, #fb5517 -8.32%, #d40a0a 123.86%);
  border-radius: 43.5px;
}
@media screen and (min-width: 250px) and (max-width: 600px) {
  .newsletter_title {
    font-size: 40px;
  }
  .newsletter_subheading {
    font-size: 22px;
  }
  .newsletter_description {
    font-size: 20px;
  }
  .newsletter form {
    flex-direction: column;
    grid-gap: 30px;
    gap: 30px;
    margin-bottom: 20px;
  }
  .newsletter input[type="email"] {
    width: 100%;
    font-size: 20px;
    height: 50px;
  }
  .NewsletterButton {
    width: 200px;
    font-size: 25px;
    line-height: 50px;
  }
}

  .newsBox{
    padding: 5px;
  }
  .recentNewsTitle {
    background-position: center;
    margin: 30px 0 0px;
    text-align: center;
    min-height: 20px;
    font-size: 40px;
    color: #FB5517;
    font-weight:700;
  }
  .sectionClass{
    height: 300px;
    overflow-y: scroll;
    padding-left: 100px;
    padding-right: 100px;
    padding-left: 1%;
    padding-right: 10%;
    width: 70%;
    margin-left: 15%;
  }
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
  }
  .card{
    border: 2px solid black;
    border-color: #0016324a !important;
    --darkreader-inline-bgcolor: rgba(30, 32, 33, 0.92);
  }
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
  #projectFacts > div> div > div > div.col-sm-10 > a{
    font-size: 18px;
    color:#001632;
  }
  #projectFacts > div> div > div > div.col-sm-10 > a:hover{
    color:#FB5517;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #FB5517; 
    border-radius: 3px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #FB5517; 
  }
  .recentNewsTitle h2 {
    font-size: 30px;
    color: #222;
    margin-bottom: 0px;
    padding-right: 10px;
    padding-left: 10px;
  }

  @media screen and (min-width: 250px) and (max-width: 600px) {

  .sectionClass{
    height: 200px;
    overflow-y: scroll;
    padding-left: 1%;
    padding-right: 10%;
    width: 85%;
    margin-left: 5%;
  }
  .recentNewsTitle{
    font-size: 30px;
  }
  #projectFacts > div> div > div > div.col-sm-10 > a{
    font-size: 14px;
  }
}
.sectionClass {
  padding: 0px 0px 150px 0px;
  position: relative;
  display: block;
}

.fullWidth {
  width: 100% !important;
  display: table;
  float: none;
  padding: 0;
  min-height: 1px;
  height: 100%;
  position: relative;
}

.sectiontitle {
  background-position: center;
  margin: 30px 0 0px;
  text-align: center;
  min-height: 20px;
}

.sectiontitle h2 {
  font-size: 30px;
  color: #222;
  margin-bottom: 0px;
  padding-right: 10px;
  padding-left: 10px;
}

.headerLine {
  width: 160px;
  height: 2px;
  display: inline-block;
  background: #101F2E;
}

.projectFactsWrap{
    display: flex;
  margin-top: 30px;
  flex-direction: row;
  flex-wrap: wrap;
}

#projectFacts .fullWidth{
  padding: 0;
}

.projectFactsWrap .item{
  width: 25%;
  height: 100%;
  padding: 50px 0px;
  text-align: center;
}

.projectFactsWrap .item:nth-child(1){
  background: rgb(248 85 23 / 82%);
}

.projectFactsWrap .item:nth-child(2){
  background: rgb(248 85 23 / 85%);
}

.projectFactsWrap .item:nth-child(3){
  background: rgb(248 85 23 / 90%);
}

.projectFactsWrap .item:nth-child(4){
  background: rgb(248 85 23 / 98%);
}

.projectFactsWrap .item p.number{
  font-size: 40px;
  padding: 0;
  font-weight: bold;
}

.projectFactsWrap .item p{
  color: rgba(255, 255, 255, 0.8);
  font-size: 28px;
  margin: 0;
  padding: 10px;
  font-family: 'Open Sans';
}

.projectFactsWrap .item span{
  width: 60px;
  background: rgba(255, 255, 255, 0.8);
  height: 2px;
  display: block;
  margin: 0 auto;
}

.projectFactsWrap .item i{
  vertical-align: middle;
  font-size: 50px;
  color: rgba(255, 255, 255, 0.8);
}

.projectFactsWrap .item:hover i, .projectFactsWrap .item:hover p{
  color: white;
}

.projectFactsWrap .item:hover span{
  background: white;
}

@media (max-width: 786px){
  .projectFactsWrap .item {
     flex: 0 0 50%;
  }
}

.inner {
    max-width: 75em;
    padding-left: 10px;
    padding-right: 10px;
    text-align: justify;
    margin: 0 auto;
    margin-bottom: 5%;
}

section.wrapper header, article.wrapper header {
    margin-bottom: 2em;
    margin-top: 1em;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding: 15px;
}

.card {
    min-width: 0;
    display: flex;
    position: relative;
    display: -ms-flexbox;
    word-wrap: break-word;
    flex-direction: column;
    border-radius: 0.25rem;
    background-clip: border-box;
    text-align: center !important;
    background-color: rgb(24, 26, 27);
    border: 1px solid rgba(0,0,0,.125);
    border-color: rgba(140, 130, 115, 0.13);
    --darkreader-inline-bgcolor: rgba(30, 32, 33, 0.92);
}

.card-body {
    flex: 1 1 auto;
    padding: 1rem;
    padding-left: 1.2rem;
    padding-right: 0px ;
}

.news_section {
  padding: 5%;
}

.news_section_title {
  margin-top: -2%;
  font-family: Abhaya Libre;
  font-style: normal;
  font-weight: bold;
  font-size: 54px;
  text-align: center;
  line-height: 75px;
  color: #fb5517;
}

.news_section_container {
  display: flex;
  grid-gap: 40px;
  gap: 40px;
}

.news_section a {
  text-decoration: none;
  color: #040d19;
}

.news_section_left a {
  display: flex;
  flex-direction: column;
  margin-left: 5%;
}

.news_section_left_name {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  margin-top: -2%;
  margin-left: 5%;
  text-align: justify;
  color: #fb5517;
}

.news_section_left_description {
  font-family: Work Sans;
  font-style: normal;
  text-align: justify;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  color: #040d19;
  margin-left: 5%;

  margin-top: -3%;
}

.news_section_left img {
  width: 100%;
  height: 100%;
}

.news_section_left {
  width: 50%;
}

.news_section_right {
  margin-top: -2%;
  width: 50%;
  display: flex;
  flex-direction: column;
}

.news_section_right_box>a {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
}

.news_section_right_box_right_news {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  text-align: justify;

  color: #fb5517;
}

.news_section_right_box_left>img {
  width: 200px;
  height: 200px;
  background-repeat: no-repeat;
}

.news_section_right_box_right_news_description {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  text-align: justify;
  font-size: 20px;
  line-height: 32px;
  color: #040d19;
}

.news_section_read_all {
  font-family: Copse;
  font-style: normal;
  font-weight: normal;
  font-size: 33.7143px;
  line-height: 54px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #fb5517;
  cursor: pointer;
}

@media screen and (min-width: 250px) and (max-width: 600px) {
  .news_section_title {
    line-height: normal;
  }

  .news_section_container {
    flex-direction: column;
  }

  .news_section_left {
    width: 100%;
  }

  .news_section_right {
    width: 100%;
  }

  .news_section_right_box_left>img {
    width: 200px;
    height: 200px;
  }

  .news_section_right_box_right_news_description {
    font-size: 20px;
  }

  .news_section_right_box>a {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
}

.WhatTheySay {
  padding: 1% 5%;
  background: #011633;
}
.whattheysay_title {
  font-family: Copse;
  font-style: normal;
  font-weight: normal;
  font-size: 45.4757px;
  line-height: 80px;
  color: #fb5517;
}

.whattheysay_slider {
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 5px solid #fb5517;
  box-sizing: border-box;
  box-shadow: 24px 25px 40px rgba(0, 0, 0, 0.25);
  justify-content: space-around;
  padding: 2%;
  grid-gap: 30px;
  gap: 30px;
  margin-bottom: 50px;
  height: 500px;
}

.whattheysay_item_title {
  font-family: Copse;
  font-style: normal;
  font-weight: normal;
  font-size: 35.0381px;
  line-height: 52px;
  /* text-align: justify; */
  color: #fb5517;
  margin: 0;
}
.whattheysay_subtitle {
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  text-align: justify;
  font-size: 32.7536px;
  margin: 0;
  color: #000000;
}

.whattheysay_description {
  font-family: Work Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 25.7499px;
  text-align: justify;
  line-height: 36px;
  color: #000000;
}
.whattheysay_slider_left > img {
  width: 300px;
  height: 300px;
  border-radius: 50%;
}
.WhatTheySay .swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  border: 3px solid #fb5517;
  box-sizing: border-box;
}
.WhatTheySay .swiper-pagination-bullet-active {
  background: #fb5517;
}
.WhatTheySay .swiper-button-prev,
.WhatTheySay .swiper-button-next {
  color: #011633;
  z-index: 0;
}

@media screen and (min-width: 250px) and (max-width: 600px) {
  .whattheysay_title {
    font-size: 25px;
    line-height: normal;
  }
  .whattheysay_item_title {
    line-height: normal;
    font-size: 20px;
  }
  .whattheysay_subtitle {
    font-size: 16px;
    line-height: normal;
  }
  .whattheysay_description {
    font-size: 13px;
    line-height: normal;
  }
  .whattheysay_slider_left > img {
    width: 100px;
    height: auto;
  }
  .whattheysay_slider {
    padding: 5%;
    grid-gap: 5%;
    gap: 5%;
    flex-direction: column;
    height: 420px;
  }
}

.landingbanner {
  /* min-height: 100vh; */
  background-color: #011633;
  background-image: url(/static/media/banner.fd78668c.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  background-position-y: -20px;
  /* margin-top: -20px; */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: justify;
  flex-direction: column;
}

.banner_box {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.banner_heading {
  font-family: Copse;
  font-style: normal;
  font-weight: normal;
  font-size: clamp(90px, 50%, 100px);
  /* line-height: 141px; */
  color: #f6faff;
  text-align: justify;
}
.banner_subtitle {
  margin-top: -90px;
  font-family: Copse;
  font-style: normal;
  font-weight: normal;
  font-size: 51.283px;
  line-height: 76px;
  color: #f6faff;
  text-align: justify;
}
.banner_subtitle2 {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18.6805px;
  line-height: 16px;
  color: #e6edf8;
  margin-top: 10px;
  text-align: justify;
}
.banner_orange {
  color: #fb5517;
}

.banner_description {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 28.0694px;
  width: 75%;
  word-wrap: break-word;
  word-break: break-word;
  line-height: 45px;
  text-align: center;
  color: #e6edf8;
  margin-top: -5px;
}

.buttoncontainer {
  display: flex;
  grid-gap: 40px;
  gap: 40px;
}
.banner_button1 {
  cursor: pointer;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 29.8564px;
  line-height: 47px;
  color: #e7f1ff;
  background: #fb5517;
  border-radius: 32.5494px;
  width: 250px;
  border-style: none;
}
.banner_button2 {
  cursor: pointer;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 29.8564px;
  line-height: 47px;
  color: #e7f1ff;
  background-color: transparent;
  border-radius: 32.5494px;
  width: 250px;
  border: 2.53631px solid #fb5517;
  box-sizing: border-box;
  border-radius: 32.5494px;
}

.buttoncontainer > button:hover {
  background: linear-gradient(94.6deg, #fb5517 36.28%, #ed1e1e 113.49%);
  border-radius: 32.5494px;
}

.landingbanner > svg {
  /* margin-top: 40px; */
  margin-bottom: 15vh;
}

@media screen and (min-width: 250px) and (max-width: 600px) {
  .landingbanner{
    height: auto;
  }
  .landingbanner > svg {
    margin-top: 20px;
    margin-bottom: 5vh;
  }
  .banner_heading {
    font-size: 38.0006px;
  }
  .banner_box {
    margin-top: 30px;
  }
  .banner_subtitle {
    font-size: 15px;
    margin-top: 0;
    line-height: 20px;
    text-align: center;
  }
  .banner_subtitle2 {
    font-size: 8px;
    margin-top: 0;
    line-height: 0;
  }
  .banner_description {
    width: inherit;
    padding: 5%;
    padding-bottom: 0%;
    font-size: 18.0694px;
    line-height: 30px;
  }
  .buttoncontainer {
    grid-gap: 20px;
    gap: 20px;
  }
  .banner_button1,
  .banner_button2 {
    width: 150px;
    font-size: 20px;
  }
}

.about {
  display: flex;
  flex-direction: column;
  padding: 5%;
  background: #ffffff;
  /* margin-top: -50px; */
}
.about_title {
  font-family: Copse;
  font-style: normal;
  font-weight: normal;
  font-size: 64.4757px;
  line-height: 80px;
  color: #fb5517;
}

.about_para1 {
  display: flex;
  flex-direction: column;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 25.7499px;
  /* line-height: 40px; */
  color: #000000;
  text-align: justify;
}

.about_description2 {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 25.7499px;
  /* line-height: 40px; */
  text-align: justify;
  color: #fb5517;
}

.about_para2 {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 25.7499px;
  line-height: 132%;
  text-align: justify;
  color: #000000;
}

.about_cardsbox {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 50px;
}

.cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  /* justify-content: space-evenly; */
  width: 350px;
  height: 250px;
  background: #011633;
  box-shadow: 9.91933px 10.9113px 39.6773px rgba(0, 0, 0, 0.15);
  border-radius: 4.95967px;
  padding: 50px;
}

.about_card_title {
  /* margin-top: -5px; */
  font-family: Copse;
  font-style: normal;
  font-weight: normal;
  font-size: 40.4757px;
  /* line-height: 80px; */
  color: #fb5517;
}
.about_card_description {
  margin-top: -5px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 20.7499px;
  line-height: 132%;
  text-align: center;
  letter-spacing: 0.015em;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
}

@media screen and (min-width: 250px) and (max-width: 600px) {
  .about_title {
    line-height: normal;
    font-size: 44px;
  }
  .about_para1,
  .about_para2,
  .about_description2 {
    font-size: 20px;
  }
  .about_cardsbox {
    margin-top: 0px;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
  }
  .cards {
    width: 200px;
    height: 180px;
    padding: 5%;
  }
  .about_card_title {
    font-size: 20px;
  }
  .about_card_description {
    font-size: 15px;
  }
}

.teams {
  padding: 5%;
  margin-top: -100px;
}
.team_title {
  font-family: Copse;
  font-style: normal;
  font-weight: normal;
  font-size: 54.4757px;
  line-height: 80px;
  color: #fb5517;
}

.technical_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -50px;
}

.technical_title,
.students_title,
.intern_title,
.publisher_title {
  font-family: Copse;
  font-style: normal;
  font-weight: normal;
  font-size: 40.637px;
  line-height: 55px;
  color: #011633;
}

.technical_grid {
  display: grid;
  grid-gap: 5%;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-content: center;
}

.technical_grid_card {
  background: #f3f5f9;
  border: 0.991933px solid #d7d7d7;
  box-sizing: border-box;
  box-shadow: 3.96773px 3.96773px 39.6773px rgba(0, 0, 0, 0.1);
  border-radius: 4.95967px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  height: 400px;
  padding: 5%;
}

.technical_grid > a,
.students_grid > a,
.intern_grid > a,
.publisher_grid > a {
  text-decoration: none;
}

.technical_name,
.students_name,
.intern_name,
.publisher_name {
  font-family: Copse;
  font-style: normal;
  font-weight: normal;
  font-size: 20.5274px;
  line-height: 29px;
  color: #000000;
  text-align: justify;
}

.technical_description,
.students_description,
.intern_description,
.publisher_description {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18.5274px;
  line-height: 28px;
  text-align: justify;
  text-align: center;
  color: #000000;
}
.students_container,
.intern_container,
.publisher_container {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.students_grid {
  display: grid;
  grid-gap: 3%;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-content: center;
}

.students_grid_card {
  background: #f3f5f9;
  border: 0.991933px solid #d7d7d7;
  box-sizing: border-box;
  box-shadow: 3.96773px 3.96773px 39.6773px rgba(0, 0, 0, 0.1);
  border-radius: 4.95967px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 300px;
}

.students_grid_card > img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.intern_grid_card > img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.intern_grid {
  display: grid;
  grid-gap: 3%;
  grid-template-columns: repeat(3, 1fr);
}

.intern_grid_card {
  background: #f3f5f9;
  border: 0.991933px solid #d7d7d7;
  box-sizing: border-box;
  box-shadow: 3.96773px 3.96773px 39.6773px rgba(0, 0, 0, 0.1);
  border-radius: 4.95967px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 300px;
}

.publisher_grid {
  display: grid;
  grid-gap: 3%;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-content: center;
}
.publisher_grid_card {
  background: #f3f5f9;
  border: 0.991933px solid #d7d7d7;
  box-sizing: border-box;
  box-shadow: 3.96773px 3.96773px 39.6773px rgba(0, 0, 0, 0.1);
  border-radius: 4.95967px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 300px;
}

.publisher_grid_card > div {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 123px;
  height: 123px; */
  background-color: red;
  border-radius: 50%;
}

.publisher_grid_card > div > img {
  width: 100px;
  height: 100px;
}
@media screen and (min-width: 250px) and (max-width: 600px) {
  .technical_grid_card,
  .students_grid_card,
  .intern_grid_card,
  .publisher_grid_card {
    width: 100%;
    height: 300px;
  }
  .team_title {
    margin-top:30%;
    font-family: Copse;
    font-style: normal;
    font-weight: normal;
    font-size: 42.4757px;
    line-height: 40px;
    color: #fb5517;
  }
  
  .teams {
    padding: 2%;
    margin-bottom: 30px;
  }
  .teams img {
    width: 100px;
    height: 100px;
  }
  .teams .swiper-button-prev,
  .swiper-button-next {
    color: #fb5517;
  }
  .technical_name,
  .students_name,
  .intern_name,
  .publisher_name {
    text-align: center;
    font-size: 20px;
    text-decoration: none;
    line-height: normal;
  }
  .technical_description,
  .students_description,
  .intern_description,
  .publisher_description {
    font-size: 12px;
    line-height: normal;
  }
  .teams .swiper-container {
    width: 100vw;
  }
  .teams a {
    text-decoration: none;
    color: inherit;
  }
}
.teams_swiper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.team_intern6 {
  height: 123px;
  width: 123px;
}

.donation {
  display: flex;
  flex-direction: column;
}
.donation_buttons {
  display: flex;
  cursor: pointer;
}
.donation_button1 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 350px;
  height: 80px;
  background: #02306f;
  border-radius: 29px 25px 0px 0px;
  font-family: Copse;
  font-style: normal;
  font-weight: normal;
  font-size: 40.8696px;
  line-height: 63px;
  /* identical to box height */
  color: #fb5517;
}
.donation .active {
  background: #011633;
  border-radius: 29px 29px 0px 0px;
}
.donation_button2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 80px;
  background: #02306f;
  border-radius: 29px 25px 0px 0px;
}

.donationIndian,
.donationNri {
  /* display: flex; */
  padding: 0% 5% 0% 5%;
  background: #011633;
  box-shadow: 9.91933px 12.8951px 247.983px rgba(0, 0, 0, 0.1);
}

.donation_container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.donation_title {
  /* margin-top: -1%; */
  font-family: Copse;
  font-style: normal;
  font-weight: normal;
  font-size: 54.4757px;
  line-height: 80px;
  color: #fb5517;
}

.donation_left {
  padding-left: 4%;
  margin-top: -2%;
  width: 50%;
}

.donation_subtitle {
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16.802px;
  line-height: 31px;
  color: #ffffff;
  text-align: justify;
}
.donation_description {
  margin-top: -2%;
  font-family: Copse;
  font-style: normal;
  font-weight: normal;
  font-size: 34.637px;
  /* line-height: 55px; */
  text-align: justify;
  color: #fb5517;
}

.donation_step_content {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 25.7499px;
  line-height: 36px;
  color: #ffffff;
}

.donation_steps ul {
  list-style: none;
}

.donation_step_footer {
  margin-left: 5%;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 15.8709px;
  line-height: 19px;
  color: #ffffff;
}

.donation_steps ul li::before {
  content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #fb5517; /* Change the color */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

.donation_button {
  margin-top: 5%;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 25.7499px;
  line-height: 36px;
  color: #ffffff;
  width: 200px;
  height: 50px;
  background: #fb5517;
  border-radius: 39.1814px;
  border-style: none;
  cursor: pointer;
}

.donation_button:hover {
  background: linear-gradient(91.8deg, #fb5517 -10.9%, #d40a0a 117.11%);
  border-radius: 32.5494px;
}

.donation_button_footer {
  /* margin-left: 5%; */
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 15.8709px;
  line-height: 19px;
  color: #ffffff;
}

.donation_right > img {
  height: 400px;
  width: 600px;
}

.donation_tax {
  color: #8b8686;
}

@media screen and (min-width: 250px) and (max-width: 600px) {
  .donation_button1,
  .donation_button2 {
    font-size: 25px;
    width: 250px;
    height: 50px;
  }
  .donation_title {
    font-size: 30px;
    line-height: normal;
  }
  .donation_container {
    flex-direction: column-reverse;
  }
  .donation_right > img {
    height: 200px;
    width: 90vw;
  }
  .donation_left {
    width: 100%;
  }
  .donation_step_content {
    font-size: 20px;
  }
  .donation_description {
    font-size: 25px;
  }
  .donation_button {
    font-size: 20px;
    width: 150px;
  }
}

.newspage {
  padding: 1%;
}
.col-sm-4{
  margin-left: 5%;
  margin-top: 5%;
}
.newspage_card {
  /* margin-top: 100px;  */
  padding: 5%;
  width: 25vw;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid #f7571cc0;
  box-shadow: 3.96773px 3.96773px 39.6773px rgba(0, 0, 0, 0.1);
  border-radius: 40.95967px;
}

.newspage_title {
  font-family: Copse;
  font-style: normal;
  font-weight: normal;
  font-size: 50.4757px;
  /* identical to box height */
  text-align: center;
  color: #fb5517;
  margin-bottom: 5vh;
  margin-top: 100px;
}
.newspage_card span{
  /* margin-top:4vh; */
  font-size: 1em;
  text-align: center;
  font-weight: 700;
}
.newspage_card p {
  font-family: Work Sans;
  font-weight: 500;
  font-size: 1.1em;
  text-align: center;
  color: #000000;
}

.newspage_card img {
  border-radius: 5%;
  width: auto;
  height: 200px;
  object-fit: fill;
}

.newspage>div {
  display: grid;
  align-items: center;
  justify-content: center;
  place-items: center;
  grid-gap: 20px;
  grid-template-columns: repeat(3, 1fr);
}
.row{
  display: -webkit-inline-box !important;
}
@media screen and (min-width: 250px) and (max-width: 600px) {
  .newspage>div {
    display: flex;
    flex-direction: column;
    margin-top: 0;
    align-items: center;
    justify-content: center;
  }
  .newspage_title {
    font-size: 30px;
    margin-top: 80px;
  }
  .newspage>div>a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .newspage_card {
    width: 80vw;
    margin-top: 0;
    padding: 5%;
    height: auto;
  }

  .newspage_card img {
    width: auto;
    height: 150px;
  }
   .row{
  display: flex !important;
}

}
@media screen and (min-width: 600px) and (max-width: 1000px) {
  .newspage_card img {
    width: 200px;
    height: auto;
  }
}
.media_coverage_card {
  height: auto;
  min-height: 350px;
}

.media_coverage_section_right_box {
  text-align: center;
}

.container {
    text-align: center;
    margin: none;
}

.box1_container {
    display: inline-block;
}

/* .box1 {
    /* width: 200px; */
    /* display: inline-block; */
    /* background-color: rgb(173, 233, 213); */
    /* max-height: 150px;
    margin: 10px 5vw 0 5vw; */
    /* border-radius: 20px; */
    /* background-image: url("https://raw.githubusercontent.com/prpankajsingh/udaanproject/main/assets/img/windows.webp"); */
    /* background-size: cover; */
    /* background-position: center; */
/* }  */

.box1 img, .box2 img{
    max-height: 150px;
    /* margin: 10px 5vw 0 5vw; */
    border-radius: 20px;
}

/* .box2 {
    width: 200px;
    display: inline-block;
    background-color: rgb(173, 233, 213);
    height: 150px;
    margin: 10px 5vw 0 5vw;
    border-radius: 20px;
    /* background-image: url("https://raw.githubusercontent.com/prpankajsingh/udaanproject/main/assets/img/ubuntu.jpg"); */
    /* background-size: cover;
    background-position: center; */
/* }  */


.box1_container .box1:hover {}

@media screen and (max-width: 550px) {
    .box1 {
        margin: 20px 5vw;
    }

    .box2 {
        margin: 20px 5vw;
    }
    .landingbanner_div {
        padding-bottom: 5%;
    }
}

h4 {
    font-size: 38px;
    margin-top: 0%;
    text-align: center;
    padding-top: 2%;
}

.landingbanner_div {
    padding-bottom: 0%;
}

.LandingModal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 30vh;
  grid-gap: 20px;
  gap: 20px;
  padding: 10%;
}
.LandingModalBack {
  width: 200px;
  height: 40px;
  border: 2.53631px solid #fb5517;
  box-sizing: border-box;
  border-radius: 32.5494px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  transition: 1s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.LandingModalBack:hover {
  background: linear-gradient(91.14deg, #fb5517 -0.6%, #d40a0a 115.07%);
  border-radius: 32.5494px;
  color: white;
}

.landingModalTitle {
  font-family: Copse;
  font-style: normal;
  font-weight: normal;
  font-size: 50.1829px;
  color: #fb5517;
  margin: 0;
}

.landingModalDescription {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 30.7499px;
  color: #000000;
  padding: 1%;
  margin: 0;
}

@media screen and (min-width: 250px) and (max-width: 600px) {
  .landingModalTitle {
    font-size: 30px;
  }
  .landingModalDescription {
    font-size: 15px;
  }
  .LandingModalBack {
    width: 150px;
    font-size: 17px;
  }
}

.fadeInDown1 {
  animation-name: fadeInDown;
  color: #fb5517;
  text-align: left;
  font-weight: 300;
  line-height: 1.5;
  margin: 0 0 1em 0;
  font-size: 35px;
  font-weight: 700;
  font-family: 'Titillium Web', sans-serif;
  /* width:max-content; */
}
.publications{
  background-image: none !important;
  background-color: white !important;
}
.container {
  /*  */
  /* padding-right: 15px;
  padding-left: 15px; */
  margin-right: auto;
  margin-left: auto;
}
.paper-authors{
  color:#011633 !important;
}
.paper-conference{
  color:#ed1e1e;
}
.row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}


.paper {
  margin: 5px;
  padding: 10px;
  font-size: 1.2em;
  text-align: left;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 80%;
}

.paper-title {
  color: #007bff;
  font-weight: 600;
}

.paper-container {
  width: 80%;
  /* align-items: center; */
}

.publications {
  padding: 1% 10%;
}

.publications_title1 {
  font-family: Copse;
  font-style: normal;
  font-weight: normal;
  font-size: 44.4757px;
  line-height: 80px;
  /* identical to box height */
  color: #fb5517;
  margin-left: -25px;
  margin-top: 8%;
  margin-bottom: 0%;
}
@media screen and (min-width: 250px) and (max-width: 600px) {
.fadeInDown1{
  font-size: 24px;
}
.publications_title1{
  margin-top:15%;
  font-size: 34px;
  margin-left: -20px;
}
.paper-container {
  margin-top: 0%;
}
.paper{
  width:120%;
}
}
/* .publications_slider {
  margin-left: 20%;
  margin-right: 40%;
  padding: 2%;
  width: 100%;
  height: 400px;
  background: #f3f5f9;
  border: 0.991933px solid #d2d2d2;
  box-sizing: border-box;
  box-shadow: 3.96773px 3.96773px 29.758px rgba(0, 0, 0, 0.1);
}

.publications .swiper-button-prev,
.publications .swiper-button-next {
  color: black;
}

.publications_name {
  font-family: Copse;
  font-style: normal;
  font-weight: normal;
  font-size: 20.5274px;
  text-align: justify;
  line-height: 29px;
  color: #fb5517;
}

.publications_subtitle {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20.5274px;
  line-height: 28px;
  text-align: justify;
  color: #011633;
}

.publications_description {
  font-family: Work Sans;
  font-style: normal;
  text-align: justify;
  font-weight: normal;
  font-size: 20.5274px;
  line-height: 28px;
  color: #011633;
} */

/* .publications .swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  border: 3px solid #fb5517;
  box-sizing: border-box;
}

.publications .swiper-pagination-bullet-active {
  background: #fb5517;
}

@media screen and (min-width: 250px) and (max-width: 600px) {
  .publications_slider {
    margin-left: 10%;
    width: 80%;
    height: 100%;
  }

  .publications_name {
    font-size: 15px;
  }

  .publications_subtitle {
    font-size: 10px;
  }

  .publications_description {
    font-size: 10px;
  }
} */

.relatedProjects {
  padding: 1%;
  /* padding-top: 5%; */
  background: #011633;
}
.relatedProjects_title {
  font-family: Copse;
  font-style: normal;
  font-weight: normal;
  font-size: 54.4757px;
  line-height: 80px;
  color: #fb5517;
}
.relatedProjects_slider {
  padding: 2% 5%;
  display: flex;
  grid-gap: 50px;
  gap: 50px;
  margin-bottom: 40px;
}
.relatedProjects_slider_left {
  width: 50%;
}
.relatedProjects_slider_right {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: -50px;
}
.relatedProjects_slider_left > img {
  width: 100%;
  height: 80%;
}
.relatedProjects_slider_right_title {
  font-family: Copse;
  font-style: normal;
  font-weight: normal;
  font-size: 32.0381px;
  line-height: 52px;
  color: #fb5517;
}
.relatedProjects_slider_right_subtitle {
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 25.7536px;
  line-height: 38px;
  text-align: justify;
  color: #ffffff;
}
.relatedProjects_slider_right_description {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 22.7499px;
  line-height: 36px;
  text-align: justify;
  color: #ffffff;
}
.relatedProjects_slider a {
  text-decoration: none;
}
.relatedProjects_slider_right_button {
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 25.7499px;
  line-height: 36px;
  /* identical to box height */
  color: #ffffff;
  cursor: pointer;
  width: 300px;
  background: #fb5517;
  border-radius: 39.1814px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
}

.relatedProjects_slider_right_button:hover {
  background: linear-gradient(180deg, #fb5517 0%, #d40a0a 100%);
  border-radius: 39.1814px;
}

.relatedProjects .swiper-button-prev,
.relatedProjects .swiper-button-next {
  color: #ffffff;
}
.relatedProjects .swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  border: 3px solid #fb5517;
  box-sizing: border-box;
}
.relatedProjects .swiper-pagination-bullet-active {
  background: #fb5517;
}
@media screen and (min-width: 250px) and (max-width: 600px) {
  .relatedProjects_title {
    font-size: 25px;
    line-height: normal;
  }
  .relatedProjects_slider {
    flex-direction: column;
    align-items: center;
    padding: 5% 10% 0% 10%;
  }
  .relatedProjects_slider_left {
    width: 100%;
  }
  .relatedProjects_slider_right {
    width: 100%;
  }
  .relatedProjects_slider_right_title {
    font-size: 25px;
    line-height: normal;
  }
  .relatedProjects_slider_right {
    line-height: normal;
    font-size: 20px;
  }
  .relatedProjects .swiper-wrapper {
    min-height: 40vh;
  }
  .relatedProjects_slider_right_description {
    font-size: 15px;
  }
  .relatedProjects_slider_right_description {
    line-height: normal;
    font-size: 18px;
  }
}

.fadeInDown {
  animation-name: fadeInDown;
  color: #fb5517;
  width: auto;
  text-align: left;
  font-weight: 300;
  line-height: 1.5;
  margin: 0 0 1em 0;
  font-size: 1.9em;
  font-weight: 700;
  font-family: 'Titillium Web', sans-serif;
  text-decoration: underline;
}
.video{
  background-image: none !important;
  background-color: white !important;
}
.container {
  /*  */
  /* padding-right: 15px;
  padding-left: 15px; */
  margin-right: auto;
  margin-left: auto;
}
.video-title {
  color: #007bff;
  font-weight: 600;
}

.video-container {
  width: 80%;
  /* align-items: center; */
}
.row {
  width: 100%;
  display: contents;
  /* -ms-flex-wrap: wrap;
  flex-wrap: wrap; */
  margin-right: -15px;
  margin-left: -15px;
}

.video_title {
  margin-top: 8%;
  margin-bottom: 0%;
  
  /* margin-left: -5%; */
}

.video {
  padding: 1% 10%;
}

.video_title {
  padding-top:1em;
  font-family: Copse;
  font-style: normal;
  font-weight: normal;
  font-size: 2.5em;
  line-height: 40px;
  /* identical to box height */
  color: #fb5517;
}
@media screen and (min-width: 250px) and (max-width: 600px) {
.fadeInDown{
  font-size: 16px;
}
.video_title {
  margin-top: 18%;
  margin-bottom: 0%;
  font-size: 24px;
  
}

}

.landingbanners-glossary {
    width: 100%;
    display: flex;
    color: #011633;
    text-align: justify;
    align-items: center;
    justify-content: center;
    padding-top: 8%;
    flex-direction: column;
    background-size: cover;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
    background-color: #72a3e7 !important;
}
@media screen and (min-width: 480px) {
    #GlossV{
        width: 80%;
    }
}

.container {
    /* margin-top: -20px; */
    color: #011633;
    background-color: #FFF;
    display: flex;
    text-align: justify;
    /* padding-bottom: 20%; */
    margin-bottom: 5%;
    flex-direction: column;
}

#example {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

#example td,
#example th {
    border: 1px solid #011633;
    padding: 8px;
}

#example tr:hover {
    background-color: #011633;
    color: #fff;
}

#example th {
    padding-top: 12px;
    padding-bottom: 12px;
    /* text-align: left; */
    background-color: #fb5517;
    color: #011633;
}

select option {
    margin: 40px !important;
    background: rgba(0, 0, 0, 0.3) !important;
    color: #011633 !important;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4) !important;
}
.MuiInputLabel-outlined.MuiInputLabel-shrink {
    color: #011633;
    background-color: #FFF !important;
}
.glossary-div {
    display: flex;
    padding: 2%;
    width: 25%;
}

#sourceLanguage,
#targetLanguage,
#domain {
    width: 20em;
}

@media (max-width: 600px) {
    .glossary-div {
        width: 100%;
    }
}

.color-link {
    color: #eb522f;
}
.App {
  box-sizing: border-box;
}

body {
  /* font-size: 62.5%; */
  margin: 0;
  padding: 0;
}
a {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

