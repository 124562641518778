@import url(https://fonts.googleapis.com/css?family=PT+Sans+Narrow);

.sectionClass {
  padding: 0px 0px 150px 0px;
  position: relative;
  display: block;
}

.fullWidth {
  width: 100% !important;
  display: table;
  float: none;
  padding: 0;
  min-height: 1px;
  height: 100%;
  position: relative;
}

.sectiontitle {
  background-position: center;
  margin: 30px 0 0px;
  text-align: center;
  min-height: 20px;
}

.sectiontitle h2 {
  font-size: 30px;
  color: #222;
  margin-bottom: 0px;
  padding-right: 10px;
  padding-left: 10px;
}

.headerLine {
  width: 160px;
  height: 2px;
  display: inline-block;
  background: #101F2E;
}

.projectFactsWrap{
    display: flex;
  margin-top: 30px;
  flex-direction: row;
  flex-wrap: wrap;
}

#projectFacts .fullWidth{
  padding: 0;
}

.projectFactsWrap .item{
  width: 25%;
  height: 100%;
  padding: 50px 0px;
  text-align: center;
}

.projectFactsWrap .item:nth-child(1){
  background: rgb(248 85 23 / 82%);
}

.projectFactsWrap .item:nth-child(2){
  background: rgb(248 85 23 / 85%);
}

.projectFactsWrap .item:nth-child(3){
  background: rgb(248 85 23 / 90%);
}

.projectFactsWrap .item:nth-child(4){
  background: rgb(248 85 23 / 98%);
}

.projectFactsWrap .item p.number{
  font-size: 40px;
  padding: 0;
  font-weight: bold;
}

.projectFactsWrap .item p{
  color: rgba(255, 255, 255, 0.8);
  font-size: 28px;
  margin: 0;
  padding: 10px;
  font-family: 'Open Sans';
}

.projectFactsWrap .item span{
  width: 60px;
  background: rgba(255, 255, 255, 0.8);
  height: 2px;
  display: block;
  margin: 0 auto;
}

.projectFactsWrap .item i{
  vertical-align: middle;
  font-size: 50px;
  color: rgba(255, 255, 255, 0.8);
}

.projectFactsWrap .item:hover i, .projectFactsWrap .item:hover p{
  color: white;
}

.projectFactsWrap .item:hover span{
  background: white;
}

@media (max-width: 786px){
  .projectFactsWrap .item {
     flex: 0 0 50%;
  }
}

.inner {
    max-width: 75em;
    padding-left: 10px;
    padding-right: 10px;
    text-align: justify;
    margin: 0 auto;
    margin-bottom: 5%;
}

section.wrapper header, article.wrapper header {
    margin-bottom: 2em;
    margin-top: 1em;
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding: 15px;
}

.card {
    min-width: 0;
    display: flex;
    position: relative;
    display: -ms-flexbox;
    word-wrap: break-word;
    flex-direction: column;
    border-radius: 0.25rem;
    -ms-flex-direction: column;
    background-clip: border-box;
    text-align: center !important;
    background-color: rgb(24, 26, 27);
    border: 1px solid rgba(0,0,0,.125);
    border-color: rgba(140, 130, 115, 0.13);
    --darkreader-inline-bgcolor: rgba(30, 32, 33, 0.92);
}

.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem;
    padding-left: 1.2rem;
    padding-right: 0px ;
}
