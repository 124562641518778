.landingbanners-glossary {
    width: 100%;
    display: flex;
    color: #011633;
    text-align: justify;
    align-items: center;
    justify-content: center;
    padding-top: 8%;
    flex-direction: column;
    background-size: cover;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
    background-color: #72a3e7 !important;
}
@media screen and (min-width: 480px) {
    #GlossV{
        width: 80%;
    }
}

.container {
    /* margin-top: -20px; */
    color: #011633;
    background-color: #FFF;
    display: flex;
    text-align: justify;
    /* padding-bottom: 20%; */
    margin-bottom: 5%;
    flex-direction: column;
}

#example {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

#example td,
#example th {
    border: 1px solid #011633;
    padding: 8px;
}

#example tr:hover {
    background-color: #011633;
    color: #fff;
}

#example th {
    padding-top: 12px;
    padding-bottom: 12px;
    /* text-align: left; */
    background-color: #fb5517;
    color: #011633;
}

select option {
    margin: 40px !important;
    background: rgba(0, 0, 0, 0.3) !important;
    color: #011633 !important;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4) !important;
}
.MuiInputLabel-outlined.MuiInputLabel-shrink {
    color: #011633;
    background-color: #FFF !important;
}
.glossary-div {
    display: flex;
    padding: 2%;
    width: 25%;
}

#sourceLanguage,
#targetLanguage,
#domain {
    width: 20em;
}

@media (max-width: 600px) {
    .glossary-div {
        width: 100%;
    }
}

.color-link {
    color: #eb522f;
}