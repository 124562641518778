.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  color: #fb5517;
  width: auto;
  text-align: left;
  font-weight: 300;
  line-height: 1.5;
  margin: 0 0 1em 0;
  font-size: 1.9em;
  font-weight: 700;
  font-family: 'Titillium Web', sans-serif;
  text-decoration: underline;
}
.video{
  background-image: none !important;
  background-color: white !important;
}
.container {
  /*  */
  /* padding-right: 15px;
  padding-left: 15px; */
  margin-right: auto;
  margin-left: auto;
}
.video-title {
  color: #007bff;
  font-weight: 600;
}

.video-container {
  width: 80%;
  /* align-items: center; */
}
.row {
  width: 100%;
  display: contents;
  /* -ms-flex-wrap: wrap;
  flex-wrap: wrap; */
  margin-right: -15px;
  margin-left: -15px;
}

.video_title {
  margin-top: 8%;
  margin-bottom: 0%;
  
  /* margin-left: -5%; */
}

.video {
  padding: 1% 10%;
}

.video_title {
  padding-top:1em;
  font-family: Copse;
  font-style: normal;
  font-weight: normal;
  font-size: 2.5em;
  line-height: 40px;
  /* identical to box height */
  color: #fb5517;
}
@media screen and (min-width: 250px) and (max-width: 600px) {
.fadeInDown{
  font-size: 16px;
}
.video_title {
  margin-top: 18%;
  margin-bottom: 0%;
  font-size: 24px;
  
}

}
