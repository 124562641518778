  .newsBox{
    padding: 5px;
  }
  .recentNewsTitle {
    background-position: center;
    margin: 30px 0 0px;
    text-align: center;
    min-height: 20px;
    font-size: 40px;
    color: #FB5517;
    font-weight:700;
  }
  .sectionClass{
    height: 300px;
    overflow-y: scroll;
    padding-left: 100px;
    padding-right: 100px;
    padding-left: 1%;
    padding-right: 10%;
    width: 70%;
    margin-left: 15%;
  }
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
  }
  .card{
    border: 2px solid black;
    border-color: #0016324a !important;
    --darkreader-inline-bgcolor: rgba(30, 32, 33, 0.92);
  }
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
  #projectFacts > div> div > div > div.col-sm-10 > a{
    font-size: 18px;
    color:#001632;
  }
  #projectFacts > div> div > div > div.col-sm-10 > a:hover{
    color:#FB5517;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #FB5517; 
    border-radius: 3px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #FB5517; 
  }
  .recentNewsTitle h2 {
    font-size: 30px;
    color: #222;
    margin-bottom: 0px;
    padding-right: 10px;
    padding-left: 10px;
  }

  @media screen and (min-width: 250px) and (max-width: 600px) {

  .sectionClass{
    height: 200px;
    overflow-y: scroll;
    padding-left: 1%;
    padding-right: 10%;
    width: 85%;
    margin-left: 5%;
  }
  .recentNewsTitle{
    font-size: 30px;
  }
  #projectFacts > div> div > div > div.col-sm-10 > a{
    font-size: 14px;
  }
}