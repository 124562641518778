.newspage {
  padding: 1%;
}
.col-sm-4{
  margin-left: 5%;
  margin-top: 5%;
}
.newspage_card {
  /* margin-top: 100px;  */
  padding: 5%;
  width: 25vw;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid #f7571cc0;
  box-shadow: 3.96773px 3.96773px 39.6773px rgba(0, 0, 0, 0.1);
  border-radius: 40.95967px;
}

.newspage_title {
  font-family: Copse;
  font-style: normal;
  font-weight: normal;
  font-size: 50.4757px;
  /* identical to box height */
  text-align: center;
  color: #fb5517;
  margin-bottom: 5vh;
  margin-top: 100px;
}
.newspage_card span{
  /* margin-top:4vh; */
  font-size: 1em;
  text-align: center;
  font-weight: 700;
}
.newspage_card p {
  font-family: Work Sans;
  font-weight: 500;
  font-size: 1.1em;
  text-align: center;
  color: #000000;
}

.newspage_card img {
  border-radius: 5%;
  width: auto;
  height: 200px;
  object-fit: fill;
}

.newspage>div {
  display: grid;
  align-items: center;
  justify-content: center;
  place-items: center;
  grid-gap: 20px;
  grid-template-columns: repeat(3, 1fr);
}
.row{
  display: -webkit-inline-box !important;
}
@media screen and (min-width: 250px) and (max-width: 600px) {
  .newspage>div {
    display: flex;
    flex-direction: column;
    margin-top: 0;
    align-items: center;
    justify-content: center;
  }
  .newspage_title {
    font-size: 30px;
    margin-top: 80px;
  }
  .newspage>div>a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .newspage_card {
    width: 80vw;
    margin-top: 0;
    padding: 5%;
    height: auto;
  }

  .newspage_card img {
    width: auto;
    height: 150px;
  }
   .row{
  display: flex !important;
}

}
@media screen and (min-width: 600px) and (max-width: 1000px) {
  .newspage_card img {
    width: 200px;
    height: auto;
  }
}
.media_coverage_card {
  height: auto;
  min-height: 350px;
}

.media_coverage_section_right_box {
  text-align: center;
}
