.Events {
  padding: 2%;
  background: #011633;
  padding-bottom: 2%;
}

.Events > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  margin-bottom: 5%;
  padding-bottom: 5%;
}

.event_title {
  font-family: Copse;
  font-style: normal;
  font-weight: normal;
  font-size: 50px;
  line-height: 55px;
  color: #fb5517;
}

.events_project_title {
  font-family: Work Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 30.7617px;
  /* line-height: 27px; */
  color: #fb5517;
}
.events_project_description {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 23.7499px;
  line-height: 36px;
  text-align: justify;
  color: #000000;
  padding-right: 15px;
}

.events_slider {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: 5%;
  margin-right: 5%;
}

.events_left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 60%;
}

.Events .swiper-button-prev,
.Events .swiper-button-next {
  color: rgb(178, 22, 22);
}
.swiper-button-prev:after, .swiper-button-next:after{
  font-size: 0px !important;
}
.events_right {
  height: clamp(60vh, 50%, 70vh);
  /* display: grid; */
  align-items: center;
  width: clamp(60vw, 10%, 25vw);
}

@media screen and (min-width: 250px) and (max-width: 600px) {
  .events_right {
    height: clamp(60vh, 50%, 70vh);
    /* display: grid; */
    align-items: center;
    width: clamp(80vw, 10%, 25vw);
  }
  .events_slider {
    flex-direction: column-reverse;
    width: 100vw;
    align-items: center;
    margin-left: 0;
    margin-right: 0;
  }
  .Events {
    padding: 0%;
  }
  .events_left {
    align-items: center;
    width: 80%;
  }
  .events_project_title {
    font-size: 20px;
    color: #fb5517;

  }
  .event_title{
    font-size: 30px;
  }

  .events_project_description {
    font-size: 15px;
    text-align: center;
    line-height: normal;
  }

  .Events {
    margin-bottom: 10%;
  }
  .Events .swiper-container {
    width: 100vw;
  }
}
