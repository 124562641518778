.LandingModal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 30vh;
  gap: 20px;
  padding: 10%;
}
.LandingModalBack {
  width: 200px;
  height: 40px;
  border: 2.53631px solid #fb5517;
  box-sizing: border-box;
  border-radius: 32.5494px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  transition: 1s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.LandingModalBack:hover {
  background: linear-gradient(91.14deg, #fb5517 -0.6%, #d40a0a 115.07%);
  border-radius: 32.5494px;
  color: white;
}

.landingModalTitle {
  font-family: Copse;
  font-style: normal;
  font-weight: normal;
  font-size: 50.1829px;
  color: #fb5517;
  margin: 0;
}

.landingModalDescription {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 30.7499px;
  color: #000000;
  padding: 1%;
  margin: 0;
}

@media screen and (min-width: 250px) and (max-width: 600px) {
  .landingModalTitle {
    font-size: 30px;
  }
  .landingModalDescription {
    font-size: 15px;
  }
  .LandingModalBack {
    width: 150px;
    font-size: 17px;
  }
}
