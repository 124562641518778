.sidebarmain {
  margin: 0;
  padding: 0;
  width: 100px;
  background-color: #011633;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  position: fixed;
  align-items: center;
  justify-content: center;
}

.sidebarmain_bg {
  margin-top: 30%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fb5517;
  cursor: pointer;
}

.sidebarmain div svg {
  height: 30px;
}

.sidebar_active {
  background-color: #ffffff;
}
