.video {
  display: flex;
  justify-content: center;
  /* background-color: rebeccapurple; */
  min-height: 80vh;
  position: relative;
}

.videocontainer {
  margin-top: 8vh;
  padding-bottom: 8vh;
  width: 90vw;
  padding-top: 10vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: #ffffff;
  gap: 5vw;
  box-shadow: 9.91933px 12.8951px 247.983px rgba(0, 0, 0, 0.1);
}

.videobox {
  margin-left: 5vw;
  /* height: clamp(60vh, 50%, 70vh); */
  display: grid;
  align-items: center;
  width: clamp(30vw, 50%, 35vw);
}

.videocontent {
  height: 50%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.video_heading {
  font-family: Copse;
  font-style: normal;
  font-weight: normal;
  font-size: 44.637px;
  line-height: 55px;
  color: #fb5517;
  text-align: justify;
}

.video_subheading {
  margin-top: -5vh;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 31.7617px;
  line-height: 37px;
  text-align: justify;
  color: #011633;
  padding-right: 10px;
}

.video_description {
  margin-top: -2vh;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 26.0694px;
  padding-right: 10px;
  text-align: justify;
  line-height: 36px;
  color: #000000;
}

.video_buttons {
  /* margin-top: 10vh; */
  display: flex;
  gap: 3vw;
}

.video_button1 {
  width: clamp(25%, 30%, 45%);
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 25.8851px;
  line-height: 37px;
  color: #e7f1ff;
  background: #fb5517;
  border-style: none;
  border-radius: 26.0395px;
}

.video_button2 {
  width: clamp(15%, 20%, 35%);
  border: 2.02905px solid #fb5517;
  box-sizing: border-box;
  border-radius: 26.0395px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 25.8851px;
  line-height: 37px;
  color: #fb5517;
  background-color: transparent;
}

.video button:hover {
  background: linear-gradient(94.6deg, #fb5517 36.28%, #ed1e1e 113.49%);
  border-radius: 32.5494px;
  color: #ffffff;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

@media screen and (min-width: 250px) and (max-width: 600px) {
  .videocontainer {
    flex-direction: column;
    margin-top: 5vh;
    padding-top: 0;
    min-height: 90vh;
  }
  .video_button2 {
    width: clamp(8%, 15%, 35%);
  }
  .video_buttons{
    display: flow-root;
    text-align: center;
  }
  .videobox {
    width: 100%;
    margin-left: 0;
    max-height: 50vh;
  }

  .video {
    min-height: 20vh;
    position: relative;
  }

  .videocontent {
    width: 100%;
    /* min-height: 25vh; */
    height: 60%;
    gap: 10px;
  }

  .video_heading {
    font-size: 20px;
    line-height: normal;
  }

  .video_subheading {
    font-size: 15px;
    line-height: normal;
  }

  .video_description {
    font-size: 14px;
    line-height: normal;
  }

  .video_button1,
  .video_button2 {
    width: 150px;
    font-size: 16px;
    margin-bottom: 3vh;
    margin-left:1vh;
  }
  
}

@media screen and (min-width: 1600px) and (max-width: 2600px) {
  .video {
    min-height: 60vh;
  }

  .videobox {
    height: 50vh;
  }

  .videocontent {
    height: 60%;
  }
  
}

button {
  cursor: pointer;
}
